import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import cn from 'classnames'

import styles from './Solupunktar.module.scss'

export default ({
  points
}) => {
  return (
    <FancyShow>
      <Container className='pt-2 pb-4'>
        <Row className='align-items-end'>
          {points && points.map(({text, icon, title}, key) => {
            return (
              <Col
                key={key}
                className={cn(
                  'd-flex flex-column align-items-center col-6 col-md-4',
                  styles.card
                )}
              >
                <div className='d-flex flex-column'>
                  <img className={styles.icon} src={icon} />
                  <h3 className='text-center'>{title}</h3>
                </div>
                <div
                  className={cn(
                    'parag-big text-center',
                    styles.text
                  )}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
                <div className={styles.cardBorder}></div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </FancyShow>
  )
}
